import antdEnUS from 'antd/es/locale/en_US';
import amMsg from "../locales/am_ET.json";

const AmLang = {
  antd: antdEnUS,
  locale: 'am-ET',
  messages: {
    ...amMsg
  },
};
export default AmLang;
