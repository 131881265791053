import {
    ApolloClient, ApolloLink, HttpLink,
    InMemoryCache,
} from "@apollo/client";


const mediaLink = new HttpLink({
    uri: process.env.REACT_APP_MEDIA_GRAPH||'http://localhost:40040/graphql',
    headers: {
        // Learn about environment variables: https://gatsby.dev/env-vars
        Authorization: `bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYyMWI1YzJmNGQ2YmRjM2NlMDIzNTRlZSIsInJvbGVzIjpbImFkbWluIl0sImlhdCI6MTY0NTk2MDI0MH0.ZSeqDBpbVw2JoooFbaGmhUqUvi-__ko_kCbaNIccikQ`,
    }
});

// Create Second Link
const emaLink = new HttpLink({
    uri: process.env.REACT_APP_EMA_GRAPH||'http://localhost:40013/graphql',
    headers: {
        // Learn about environment variables: https://gatsby.dev/env-vars
        Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYyMDg3ZjNiMzdjYWZlMzQ5NDc3ODAxYSIsInJvbGVzIjpbImFkbWluIl0sImlhdCI6MTY0NDcyNDAyN30.AFRv9yjZQCbjH6RSUFFmfm9Pg1KxDJHVx7JQnvPZ1rE`,
    }
});


export const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.split(
        operation => operation.getContext().clientName === "ema", // Routes the query to the proper client
        emaLink,
        mediaLink
    ),
});
