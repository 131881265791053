// const FirebaseConfig = {
//   apiKey: 'AIzaSyAOWoRQGoegkmwnCca7B2GG5GUG_ZP1lcM',
//   authDomain: 'emilus.firebaseapp.com',
//   databaseURL: 'https://emilus.firebaseio.com',
//   projectId: 'emilus',
//   storageBucket: 'emilus.appspot.com',
//   messagingSenderId: '807555350717',
//   appId: '1:807555350717:web:145ba7c21af47203a2f7d4',
//   measurementId: 'G-8KE7HJB252'
// };

const FirebaseConfig = {
  apiKey: "AIzaSyC-u6Jjeb0KNtRU5ZgFOzV2c7BThcktL8c",
  authDomain: "media-et.firebaseapp.com",
  projectId: "media-et",
  storageBucket: "media-et.appspot.com",
  messagingSenderId: "21855997597",
  appId: "1:21855997597:web:61693a19a08d410b814e74",
  measurementId: "G-KBPWREH6PT"
}

export default FirebaseConfig

