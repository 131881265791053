import {
  DashboardOutlined,
    WindowsOutlined,
  PlusOutlined,
  ShoppingCartOutlined,
  CommentOutlined


} from '@ant-design/icons';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig'



const dashBoardNavTree = [{
  key: 'dashboards',
  path: `${APP_PREFIX_PATH}/dashboards`,
  title: 'sidenav.dashboard',
  icon: DashboardOutlined,
  breadcrumb: false,
  submenu: []
  // submenu: [
  //   {
  //     key: 'dashboards-default',
  //     path: `${APP_PREFIX_PATH}/dashboards/default`,
  //     title: 'sidenav.dashboard.default',
  //     icon: DashboardOutlined,
  //     breadcrumb: false,
  //     submenu: []
  //   },
  //   {
  //     key: 'dashboards-analytic',
  //     path: `${APP_PREFIX_PATH}/dashboards/analytic`,
  //     title: 'sidenav.dashboard.analytic',
  //     icon: DotChartOutlined,
  //     breadcrumb: false,
  //     submenu: []
  //   },
  //   {
  //     key: 'dashboards-sales',
  //     path: `${APP_PREFIX_PATH}/dashboards/sales`,
  //     title: 'sidenav.dashboard.sales',
  //     icon: FundOutlined,
  //     breadcrumb: false,
  //     submenu: []
  //   }
  // ]
}]


const progNavTree = [
  {
    key: 'programs',
    path: `${APP_PREFIX_PATH}/programs`,
    title: 'sidenav.apps.programs',
    icon: ShoppingCartOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: 'programs-list',
        path: `${APP_PREFIX_PATH}/programs/list`,
        title: 'sidenav.apps.programs.list',
        icon: WindowsOutlined,
        breadcrumb: true,
        submenu: []
      },
      {
        key: 'apps-ecommerce-addProduct',
        path: `${APP_PREFIX_PATH}/programs/add`,
        title: 'sidenav.apps.programs.new',
        icon: PlusOutlined,
        breadcrumb: false,
        submenu: []
      },


    ]
  }
]


const complaintNavTree = [
  {
    key: 'complaints',
    path: `${APP_PREFIX_PATH}/complaints`,
    title: 'sidenav.apps.complaints',
    icon: ShoppingCartOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: 'complaints-list',
        path: `${APP_PREFIX_PATH}/complaints/list`,
        title: 'sidenav.apps.complaints.list',
        icon: CommentOutlined,
        // breadcrumb: true,
        submenu: []
      },



    ]
  }
]

const navigationConfig = [
  ...dashBoardNavTree,
    ...progNavTree,
    ...complaintNavTree,
  // ...appsNavTree,
  // ...componentsNavTree,
  // ...extraNavTree,
  // ...docsNavTree
]

export default navigationConfig;
